
import CommonUtils from '@/utils/common-utils';
import Confirm from '@/components/core/Confirm.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DateTimeUtils from '@/utils/date-time-utils';
import ScoreWeightDialog from '@/components/classwork/ScoreWeightDialog.vue';
import ld from 'lodash';
import PageLifeCycleMixin from '@/mixins/page-lifecycle-mixin';
import ClassworkMixin from '@/mixins/classwork-mixin';
import TableResizeMixin from '@/mixins/table-resize-mixin';
import { tableWidths } from '@/constants/index';

const classwork = namespace('classwork');
const google = namespace('google');
const classes = namespace('classes');
const settings = namespace('settings');

@Component({
  mixins: [PageLifeCycleMixin, ClassworkMixin, TableResizeMixin],
  components: {
    ScoreWeightDialog
  }
})
export default class Classwork extends Vue {
  tableHeight!: string;
  searchText!: string;
  editMode!: boolean;
  selectedRows!: Array<any>;
  showScoreWeightDialog!: boolean;
  filterSelectionsSelectedClass!: any;
  filterOptionsClassList!: Array<any>;
  filterOptionsShowUnitFilter!: boolean;
  filterSelectionsSelectedUnit!: any;
  filterOptionsUnitList!: Array<any>;
  filterSelectionsShowClassworkTypes!: Array<any>;
  filterSelections!: any;
  filterOptions!: any;
  localClasswork: Array<any> = [];
  filteredClasswork: Array<any> = [];

  classroomDialog: any = {
    state: false,
    param: ''
  };

  headers = [
    { text: this.$t('dueDateLabel'), value: 'classworkEnd', width: this.tableWidths.fullDate, sort: DateTimeUtils.compareDates },
    { text: this.$t('classLabel'), align: 'start', value: 'className', width: this.contentBreakpoint === 'sm' ? this.tableWidths.icon : this.tableWidths.shortText },
    { text: this.$t('unitLabel'), value: 'unitNum', width: this.tableWidths.shortText },
    { text: this.$t('typeLabel'), value: 'isAssignment', width: this.contentBreakpoint === 'sm' ? this.tableWidths.icon : this.tableWidths.shortText },
    { text: this.$t('titleLabel'), value: 'classworkTitle', class: 'header-longText' },
    { width: this.tableWidths.action2, sortable: false, value: 'action' }
  ];

  get filteredHeaders() {
    if (this.contentBreakpoint === 'sm') {
      return this.headers.filter((h: any) => h.value !== 'unitNum')
    } else {
      return this.headers;
    }
  }

  $refs!: {
    classwork: Confirm;
    table: any;
    sheet: any;
  };

  @classwork.Action
  loadClasswork!: (param: any) => Promise<any>;

  @classwork.Action
  deleteClasswork!: (param: any) => Promise<any>;

  @classwork.Action
  deleteMultipleClasswork!: (param: any) => Promise<any>;

  @classwork.Getter
  getAllClasswork!: any;

  @classes.Getter('getClassItems')
  classList!: Array<any>;

  @classwork.Getter('getListLoading')
  listLoading!: boolean;

  @classes.Getter
  getClassUnits!: any;

  @classwork.Action
  updateAssignment!: (params?:any) => Promise<any>;

  @classwork.Action
  updateAssessment!: (params?:any) => Promise<any>;

  @settings.Getter('getClassworkStyling')
  classworkStyling!: any;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @google.Action
  loadGoogleClasses!: () => Promise<any>;

  get mobileBreakpoint() {
    return CommonUtils.getMobileBreakpointSize();
  }

  get isMobileMode() {
    return CommonUtils.isMobileMode();
  }

  get mobileTableClass() {
    return CommonUtils.mobileTableClass();
  }

  get mobileRowClass() {
    return CommonUtils.mobileRowClass();
  }

  get mobileHeaderClass() {
    return CommonUtils.mobileHeaderClass();
  }

  get mobileCellClass() {
    return CommonUtils.mobileCellClass();
  }

  get contentBreakpoint() {
    return CommonUtils.mainContentBreakpoint();
  }

  get formatToDisplay() {
    return DateTimeUtils.formatToDisplay;
  }

  get localListLoading() {
    return this.listLoading;
  }

  set localListLoading(value: boolean) {
    this.$store.commit('classwork/setListLoading', value);
  }

  get tableWidths() {
    return tableWidths;
  }

  @Watch('localListLoading')
  disableEditable() {
    if (!this.localListLoading) {
      const classworks = ld.cloneDeep(this.filteredClasswork);
      classworks.forEach(e => {
        e.editClassworkTitle = false;
      });
      this.filteredClasswork = classworks;
    }
  }

  addClasswork() {
    this.$eventBus.$emit('openSubPage', {
      type: 'classwork',
      width: 600,
      modal: this.$currentUser.defaultEditorMode === 'modal',
      input: {
        action: 'A',
        loadData: true,
        classId: this.filterSelectionsSelectedClass.value,
        unit: this.filterSelectionsSelectedUnit,
        type: this.filterSelectionsShowClassworkTypes[0] || 0
      }
    });
  }

  editClasswork(param: any) {
    this.$eventBus.$emit('openSubPage', {
      type: 'classwork',
      width: (param.source === 'gc') ? 0 : 600,
      modal: this.$currentUser.defaultEditorMode === 'modal',
      input: {
        action: (param.source === 'gc') ? 'G' : 'E',
        loadData: true,
        classwork: param
      }
    });
  }

  deleteSinglePopup(classwork: any) {
    const that = this;
    this.$refs.classwork.confirm({
      title: this.$t('classworkLabel'),
      text: (classwork.isAssignment) ? this.$t('confirmDeleteAssignment') : this.$t('confirmDeleteAssessment'),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        that.localListLoading = true;
        that.deleteClasswork(classwork).then(() => {
          this.refresh();
          this.$eventBus.$emit('deleteClasswork', [classwork]);
          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('statusMsg34') as string);
          }
          return Promise.resolve();
        });
      }
    });
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
    if (this.editMode === false) {
      this.selectedRows = [];
    }
  }

  deleteMultiplePopup() {
    const that = this;
    this.$refs.classwork.confirm({
      title: this.$t('classworkLabel'),
      text: this.$t('confirmDeleteClasswork'),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        that.deleteMultiple();
      }
    });
  }

  private deleteMultiple() {
    const classworkToDelete = this.selectedRows.map((c: any) => { return { isAssignment: c.isAssignment, classworkId: c.classworkId } });
    this.localListLoading = true;
    this.deleteMultipleClasswork(this.selectedRows).then(() => {
      this.refresh();
      this.$eventBus.$emit('deleteClasswork', classworkToDelete);
      if (this.showSnackbarNotifications) {
        this.$snotify.success(this.$t('statusMsg34') as string);
      }
      return Promise.resolve();
    });
    this.selectedRows = [];
  }

  get haveClasswork() {
    return this.$store.state.classwork.haveClasswork;
  }

  set haveClasswork(value: boolean) {
    this.$store.commit('classwork/setHaveClasswork', value);
  }

  refresh() {
    this.haveClasswork = false;
    this.loadClasswork({
      teacherId: 0
    }).then(() => {
      this.localClasswork = this.getAllClasswork;
      this.determineSelectability()
      this.applyFilters();
    })
  }

  @Watch('filterSelections.selectedClass')
  @Watch('filterSelections.selectedUnit')
  @Watch('filterSelections.showClassworkTypes')
  applyFilters() {
    this.localListLoading = true;
    this.filteredClasswork = this.filterByClass(this.localClasswork);
    this.filteredClasswork = this.filterByUnits(this.filteredClasswork);
    this.filteredClasswork = this.filterType(this.filteredClasswork);
    this.localListLoading = false;
  }

  @Watch('filterSelections.selectedClass')
  unitOptions() {
    this.filterOptionsUnitList = [{ displayTitle: this.$t('allUnitsLabel'), id: 0 }];
    if (this.filterSelectionsSelectedClass.value === 0) {
      this.filterSelectionsSelectedUnit = { displayTitle: this.$t('allUnitsLabel'), id: 0 };
      this.filterOptionsShowUnitFilter = false;
    } else {
      this.filterOptionsShowUnitFilter = true;
      const allUnits = this.getClassUnits(this.filterSelectionsSelectedClass.value)
      for (const i in allUnits) {
        allUnits[i].displayTitle = allUnits[i].num + ' - ' + allUnits[i].title;
        this.filterOptionsUnitList.push(allUnits[i]);
      }
      this.filterSelectionsSelectedUnit = { displayTitle: this.$t('allUnitsLabel'), id: 0 };
    }
  }

  filterByClass(items: any) {
    if (this.filterSelectionsSelectedClass.value === 0) {
      return items;
    } else {
      return items.filter((classwork: any) => {
        return classwork.className === this.filterSelectionsSelectedClass.text;
      })
    }
  }

  filterByUnits(items: any) {
    if (this.filterSelectionsSelectedUnit.id === 0) {
      return items
    } else {
      return items.filter((classwork: any) => {
        return classwork.unitId === this.filterSelectionsSelectedUnit.id;
      });
    }
  }

  filterType(items: any) {
    const showAssignments = this.filterSelectionsShowClassworkTypes.indexOf(0) !== -1
    const showAssessments = this.filterSelectionsShowClassworkTypes.indexOf(1) !== -1
    if (showAssignments && showAssessments) {
      return items;
    } else if (showAssignments) {
      return items.filter((classwork: any) => {
        return classwork.isAssignment === true;
      });
    } else if (showAssessments) {
      return items.filter((classwork: any) => {
        return classwork.isAssignment === false;
      });
    } else {
      return [];
    }
  }

  determineSelectability() {
    for (const i in this.localClasswork) {
      this.localClasswork[i].selectable = this.localClasswork[i].source !== 'gc'
    }
  }

  initialize() {
    CommonUtils.showLoading();
    this.filterSelections = {
      showClassworkTypes: [0, 1],
      selectedClass: { text: this.$t('allClassesLabel'), value: 0 },
      selectedUnit: { displayTitle: this.$t('allUnitsLabel'), id: 0 }
    }
    this.filterOptions = {
      showUnitFilter: false,
      unitList: [{ displayTitle: this.$t('allUnitsLabel'), id: 0 }],
      classList: [this.filterSelections.selectedClass]
    }
    this.loadGoogleClasses();
    this.loadClasswork({
      teacherId: 0
    }).then(() => {
      this.localClasswork = this.getAllClasswork;
      this.determineSelectability();
      this.applyFilters();
    }).finally(() => {
      CommonUtils.hideLoading();
    });
    this.filterOptionsClassList = [this.filterSelections.selectedClass].concat(this.classList);
  }

  created() {
    this.$nextTick(() => {
      this.$eventBus.$on('refreshClasswork', () => {
        this.localListLoading = true;
        this.refresh();
      });
      this.$eventBus.$emit('openDefaultSubPage')

      this.initialize();
    })
  }

  destroyed() {
    this.$eventBus.$off('refreshClasswork');
  }

  toggleClassworkTitleEditable(item: any) {
    const classworks = ld.cloneDeep(this.filteredClasswork);
    classworks.forEach(e => {
      e.editClassworkTitle = false;
    });
    const classwork = classworks.find(e => e.classworkId === item.classworkId);
    classwork.editClassworkTitle = !classwork.editClassworkTitle;
    this.filteredClasswork = classworks;
  }

  updateClassworkTitle(item: any, value: string) {
    const classworks = ld.cloneDeep(this.filteredClasswork);
    const classwork = classworks.find(e => e.classworkId === item.classworkId);
    classwork.classworkTitle = value;
    this.filteredClasswork = classworks;
  }

  saveClassworkTitle(item: any) {
    const classworks = ld.cloneDeep(this.filteredClasswork);
    const classwork = classworks.find(c => c.classworkId === item.classworkId);
    const origClasswork = this.localClasswork.find(c => c.classworkId === item.classworkId);
    this.localListLoading = true;
    if (item.classworkTitle !== origClasswork.classworkTitle) {
      if (item.isAssignment) {
        this.updateAssignment(ld.merge(classwork, {
          assignmentId: classwork.classworkId,
          assignmentTitle: classwork.classworkTitle,
          assignmentDesc: classwork.classworkDescription,
          assignmentStart: classwork.classworkStart,
          assignmentEnd: classwork.classworkEnd,
          assignmentPoints: (Number.parseInt(classwork.classworkPoints)) ? classwork.classworkPoints : '0'
        }))
          .then(() => {
            this.localListLoading = false;
            if (this.showSnackbarNotifications) {
              this.$snotify.success(this.$t('statusMsg33') as string);
            }
          });
      } else {
        this.updateAssessment(ld.merge(classwork, {
          assessmentId: classwork.classworkId,
          assessmentTitle: classwork.classworkTitle,
          assessmentDesc: classwork.classworkDescription,
          assessmentStart: classwork.classworkStart,
          assessmentEnd: classwork.classworkEnd,
          assessmentPoints: (Number.parseInt(classwork.classworkPoints)) ? classwork.classworkPoints : '0'
        }))
          .then(() => {
            this.localListLoading = false;
            if (this.showSnackbarNotifications) {
              this.$snotify.success(this.$t('statusMsg33') as string);
            }
          });
      }
    } else {
      this.$nextTick(() => {
        this.localListLoading = false;
      })
    }
  }
}

