var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-sheet",
    { staticClass: "classwork", attrs: { color: "#ffffff" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                {
                  staticClass: "rounded-0",
                  class: { "mt-5": _vm.$vuetify.breakpoint.mdAndUp },
                  attrs: { flat: "" },
                },
                [
                  _c("v-data-table", {
                    ref: "table",
                    attrs: {
                      headers: _vm.filteredHeaders,
                      items: _vm.filteredClasswork,
                      search: _vm.searchText,
                      "item-key": "classworkId",
                      height: _vm.tableHeight,
                      "fixed-header": "",
                      "show-select": _vm.editMode,
                      "selectable-key": "selectable",
                      "mobile-breakpoint": _vm.mobileBreakpoint,
                      "hide-default-footer": "",
                      "disable-pagination": "",
                      loading: _vm.localListLoading,
                    },
                    on: { "click:row": _vm.editClasswork },
                    scopedSlots: _vm._u([
                      {
                        key: "body",
                        fn: function ({ items, isSelected, select }) {
                          return [
                            _c(
                              "tbody",
                              {
                                ref: "listContainer",
                                staticClass: "list-container",
                              },
                              _vm._l(items, function (item, index) {
                                return _c(
                                  "tr",
                                  {
                                    key: index,
                                    class: _vm.mobileTableClass,
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { tabindex: "0", role: "row" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.editClasswork(item)
                                      },
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.editClasswork(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm.editMode
                                      ? _c(
                                          "td",
                                          {
                                            class: _vm.mobileRowClass,
                                            on: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                select(item, !isSelected(item))
                                              },
                                            },
                                          },
                                          [
                                            _vm.isMobileMode
                                              ? _c("div", {
                                                  class: _vm.mobileHeaderClass,
                                                })
                                              : _vm._e(),
                                            _c("v-checkbox", {
                                              class: _vm.mobileCellClass,
                                              staticStyle: {
                                                margin: "0px",
                                                padding: "0px",
                                              },
                                              attrs: {
                                                disabled: !item.selectable,
                                                "input-value": isSelected(item),
                                                color: !_vm.$vuetify.theme.dark
                                                  ? "#7a7a7a"
                                                  : "#ffffff",
                                                "hide-details": "",
                                                "aria-label":
                                                  item.classworkTitle,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  select(
                                                    item,
                                                    !isSelected(item)
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.headers[0].text)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      item.classworkEnd
                                        ? _c(
                                            "span",
                                            { class: _vm.mobileCellClass },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatToDisplay(
                                                    item.classworkEnd,
                                                    true
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.headers[1].text)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "d-flex justify-left",
                                          class: _vm.mobileCellClass,
                                        },
                                        [
                                          _vm.contentBreakpoint === "sm"
                                            ? _c(
                                                "pb-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    label: item.className,
                                                    color: item.color,
                                                  },
                                                },
                                                [_vm._v("fa-solid fa-circle")]
                                              )
                                            : _c(
                                                "div",
                                                { staticClass: "d-flex" },
                                                [
                                                  _c(
                                                    "pb-icon",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        color: item.color,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "fa-solid fa-circle"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "10px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.className)
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm.contentBreakpoint !== "sm"
                                      ? _c(
                                          "td",
                                          { class: _vm.mobileRowClass },
                                          [
                                            _vm.isMobileMode
                                              ? _c(
                                                  "div",
                                                  {
                                                    class:
                                                      _vm.mobileHeaderClass,
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.headers[2].text
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "span",
                                              { class: _vm.mobileCellClass },
                                              [_vm._v(_vm._s(item.unitNum))]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.headers[3].text)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      item.isAssignment
                                        ? _c(
                                            "span",
                                            { class: _vm.mobileCellClass },
                                            [
                                              _vm.contentBreakpoint === "sm"
                                                ? _c(
                                                    "pb-icon",
                                                    {
                                                      attrs: {
                                                        label:
                                                          _vm.$t(
                                                            "assignmentLabel"
                                                          ),
                                                        color:
                                                          _vm.classworkStyling
                                                            .currentAssignmentColor,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "fa-regular fa-calendar-lines-pen"
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    { staticClass: "d-flex" },
                                                    [
                                                      _c(
                                                        "pb-icon",
                                                        {
                                                          attrs: {
                                                            color:
                                                              _vm
                                                                .classworkStyling
                                                                .currentAssignmentColor,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fa-regular fa-calendar-lines-pen"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "padding-left":
                                                              "10px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "assignmentLabel"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "span",
                                            { class: _vm.mobileCellClass },
                                            [
                                              _vm.contentBreakpoint === "sm"
                                                ? _c(
                                                    "pb-icon",
                                                    {
                                                      attrs: {
                                                        label:
                                                          _vm.$t(
                                                            "assessmentLabel"
                                                          ),
                                                        color:
                                                          _vm.classworkStyling
                                                            .currentAssessmentColor,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "fa-regular fa-clipboard-list-check"
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    { staticClass: "d-flex" },
                                                    [
                                                      _c(
                                                        "pb-icon",
                                                        {
                                                          attrs: {
                                                            color:
                                                              _vm
                                                                .classworkStyling
                                                                .currentAssessmentColor,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fa-regular fa-clipboard-list-check"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "padding-left":
                                                              "10px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "assessmentLabel"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                            ],
                                            1
                                          ),
                                    ]),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.headers[4].text)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      item.source == "gc"
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileCellClass },
                                            [
                                              _c("v-icon", {
                                                staticClass:
                                                  "fa-brands fa-google",
                                                staticStyle: {
                                                  "padding-right": "5px",
                                                },
                                                attrs: { small: "" },
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.classworkTitle) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            { class: _vm.mobileCellClass },
                                            [
                                              item.editClassworkTitle
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          autofocus: "",
                                                          dense: "",
                                                          "hide-details": "",
                                                          "append-icon":
                                                            "fa fa-check",
                                                          value:
                                                            item.classworkTitle,
                                                          disabled:
                                                            _vm.localListLoading,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.updateClassworkTitle(
                                                              item,
                                                              $event
                                                            )
                                                          },
                                                          blur: _vm.disableEditable,
                                                          keyup: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            )
                                                              return null
                                                            return _vm.saveClassworkTitle(
                                                              item
                                                            )
                                                          },
                                                          "click:append":
                                                            function ($event) {
                                                              return _vm.saveClassworkTitle(
                                                                item
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          $event.preventDefault()
                                                          return _vm.toggleClassworkTitleEditable(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.classworkTitle
                                                          )
                                                        ),
                                                      ]),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "ml-2 hover-edit-icon",
                                                          staticStyle: {
                                                            cursor: "pointer",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                "x-small": "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fal fa-pencil"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          ),
                                    ]),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c("div", {
                                            class: _vm.mobileHeaderClass,
                                          })
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { class: _vm.mobileCellClass },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "mx-3 hover-button-primary",
                                              attrs: {
                                                small: "",
                                                text: "",
                                                color:
                                                  _vm.contentBreakpoint === "xs"
                                                    ? "primary"
                                                    : "transparent",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  $event.stopPropagation()
                                                  return _vm.editClasswork(item)
                                                },
                                              },
                                            },
                                            [
                                              item.source == "gc"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "editGoogleClasswork"
                                                        )
                                                      )
                                                    ),
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("editLabel")
                                                      )
                                                    ),
                                                  ]),
                                            ]
                                          ),
                                          item.source !== "gc"
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "mx-3 hover-button-error",
                                                  attrs: {
                                                    text: "",
                                                    small: "",
                                                    color:
                                                      _vm.contentBreakpoint ===
                                                      "xs"
                                                        ? "error"
                                                        : "transparent",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.deleteSinglePopup(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("deleteLabel")
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.selectedRows,
                      callback: function ($$v) {
                        _vm.selectedRows = $$v
                      },
                      expression: "selectedRows",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("score-weight-dialog", {
        on: {
          close: function ($event) {
            _vm.showScoreWeightDialog = false
          },
        },
        model: {
          value: _vm.showScoreWeightDialog,
          callback: function ($$v) {
            _vm.showScoreWeightDialog = $$v
          },
          expression: "showScoreWeightDialog",
        },
      }),
      _c("pb-confirm", { ref: "classwork" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }